import { useStyles } from "./TableContentStyles";
import { TableRow, TableCell } from "@material-ui/core";
import clsx from "clsx";
import TableRoot from "components/Table/TableBase/TableRoot";
import {
  EMPLOYEE_DETAIL_LINK,
  USERS_REGISTRATION_STATUS_DEFINITIVE,
} from "config/constant";
import { fullNameOrFamilyName, getUserId } from "Utilities/appHelper";
import { AuthType } from "types/authType";
import { ApiUsersIdAsync } from "Apis/ApiUsersIdAsync";
import { useState } from "react";
import { AfterApiIsLogin } from "Utilities/AuthUtility";
import { ApiUsersInvitation } from "Apis/ApiUsersInvitation";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import MessageModal from "components/MessageModal/MessageModal";
import { ApiUsersUpdate } from "Apis/ApiUsersUpdate";
import { ApiUsersDelete } from "Apis/ApiUsersDelete";
import { useNavigate } from "react-router-dom";

interface StagingTableProps {
  columns?: any;
  rows: any;
  pageNumber: number;
  perPageNumber: number;
  sortHeader: number;
  reverseFlg: boolean;
  authType: AuthType;
  headerSort: (e: any) => void;
  //onRow: (e: any) => void;
}

var workRow: any = {};

const TableContent = ({
  pageNumber,
  perPageNumber,
  columns,
  rows,
  sortHeader,
  reverseFlg,
  authType,
  headerSort,
}: //onRow,
  StagingTableProps) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [errMsg, setErrMsg] = useState("");
  
    //------------eneable switch function------------
    const [switchConfirm, setSwitchConfirm] = useState(false);
  
    const handleSwitchConfirmClose = () => {
      workRow = {};
      setSwitchConfirm(false);
    };
  
    const handleSwitchConfirm = async () => {
      // MEMO: 要修正箇所
      var obj = {
        id: workRow.id,
        last_name: workRow.last_name,
        first_name: workRow.first_name,
        // MEMO: full_nameが必須のためこのように対応
        full_name: !!workRow.full_name
          ? workRow.full_name
          : workRow.last_name + workRow.first_name,
        phone: workRow.phone,
        business_email: workRow.business_email,
        private_email: workRow.private_email,
        image_id: workRow.image_id,
        enable: !workRow.enable,
      };
      var response: any = await ApiUsersUpdate(obj);
      loginCheck(response);
      setErrMsg("");
      if (response["result-status"] === 200) {
        workRow.enable = !workRow.enable;
        workRow = {};
        setSwitchConfirm(false);
      } else {
        setErrMsg(
          "ApiUsersUpdate\nresult-status = " +
          response["result-status"] +
          "\nmessage = " +
          response.message
        );
        setErrorMessage2ModalStatus(true);
      }
    };
  
    //------------delete flag function------------
    const [deleteModalStatus, setDeleteModalStatus] = useState(false);
  
    const handleDeleteModalOpen = async (row: any) => {
      const userId = getUserId()
      workRow = row;
      var response: any = await ApiUsersIdAsync(userId);
      loginCheck(response);
      if (workRow.id === response["result-object"].id) {
        setErrorMessageModalStatus(true);
      } else {
        setDeleteModalStatus(true);
      }
    };
  
    const handleDeleteConfirmClose = () => {
      workRow = {};
      setDeleteModalStatus(false);
    };
  
    const handleDeleteConfirm = async () => {
      workRow.delete = true;
      const response: any = await ApiUsersDelete(workRow.id);
      loginCheck(response);
      setErrMsg("");
      if (response["result-status"] === 200) {
        workRow = {};
        setDeleteModalStatus(false);
        window.location.reload();
      } else {
        setErrMsg(
          "ApiUsersDelete\nresult-status = " +
          response["result-status"] +
          "\nmessage = " +
          response.message
        );
        setErrorMessage2ModalStatus(true);
      }
    };
  
    //------------re-invitation flag function------------
    const [showReinvitationModal, setShowReinvitationModal] = useState(false);
  
    const handleReinvitationConfirmClose = () => {
      workRow = {};
      setShowReinvitationModal(false);
    };
  
    const handleReInvitation = async () => {
      // MEMO: 要修正箇所
      var obj = { id: workRow.id };
      var response: any = await ApiUsersInvitation(obj);
      loginCheck(response);
      if (response["result-status"] === 200) {
        workRow.enable = !workRow.enable;
        workRow = {};
        setShowReinvitationModal(false);
        setShowAfterModal(true);
      } else {
        setErrMsg(
          "ApiUsersUpdate\nresult-status = " +
          response["result-status"] +
          "\nmessage = " +
          response.message
        );
        setErrorMessage2ModalStatus(true);
      }
    };
  
    function loginCheck(data: any) {
      AfterApiIsLogin(data["result-status"]);
    }
  
    const [errorMessageModalStatus, setErrorMessageModalStatus] = useState(false);
    const [errorMessage2ModalStatus, setErrorMessage2ModalStatus] =
      useState(false);
  
    function handleErrorMessageModal() {
      setErrorMessageModalStatus(false);
    }
  
    function handleErrorMessage2Modal() {
      setErrorMessage2ModalStatus(false);
    }
  
    const [showAfterModal, setShowAfterModal] = useState(false);
  
    const handleCloseAfterModal = () => {
      setShowAfterModal(false);
      window.location.reload();
    };

  const tableRows =
    rows !== undefined ? (
      rows
        ?.slice(pageNumber * perPageNumber, (pageNumber + 1) * perPageNumber)
        .map((row: any, key: any) => (
          <TableRow key={key} onClick={()=>{
            navigate(`${EMPLOYEE_DETAIL_LINK}/${row.id}`)
          }}>
            <TableCell className={clsx(classes.tableCell)}>
              <span
                // to={`${EMPLOYEE_DETAIL_LINK}/${row.id}`}
                className={classes.cellA}
              >
                {row.no}
              </span>
            </TableCell>
            <TableCell
              className={clsx(
                classes.tableCell,
                row.registration_status !==
                USERS_REGISTRATION_STATUS_DEFINITIVE && classes.greyout
              )}
            >
              <span
                // to={`${EMPLOYEE_DETAIL_LINK}/${row.id}`}
                className={classes.cellA}
              >
                {fullNameOrFamilyName(
                  row.full_name,
                  row.first_name,
                  row.last_name
                )}
              </span>
            </TableCell>
            <TableCell
              className={clsx(
                classes.tableCell,
                row.registration_status !==
                USERS_REGISTRATION_STATUS_DEFINITIVE && classes.greyout
              )}
            >
              <span
                // to={`${EMPLOYEE_DETAIL_LINK}/${row.id}`}
                className={classes.cellA}
              >
                {row.business_email}
              </span>
            </TableCell>
            <TableCell
              className={clsx(
                classes.tableCell,
                row.registration_status !==
                USERS_REGISTRATION_STATUS_DEFINITIVE && classes.greyout
              )}
            >
              <span
                // to={`${EMPLOYEE_DETAIL_LINK}/${row.id}`}
                className={classes.cellA}
              >
                {row.authorities_short_display}
              </span>
            </TableCell>
            <TableCell
              className={clsx(
                classes.tableCell,
                row.registration_status !==
                USERS_REGISTRATION_STATUS_DEFINITIVE && classes.greyout
              )}
            >
              <span
                // to={`${EMPLOYEE_DETAIL_LINK}/${row.id}`}
                className={classes.cellA}
              >
                {row.registration_status_display}
              </span>
            </TableCell>
            <TableCell
              className={clsx(
                classes.tableCell,
                row.registration_status !==
                USERS_REGISTRATION_STATUS_DEFINITIVE && classes.greyout
              )}
            >
              <span
                // to={`${EMPLOYEE_DETAIL_LINK}/${row.id}`}
                className={classes.cellA}
              >
                {row.create_at_display}
              </span>
            </TableCell>
            <TableCell
              className={clsx(classes.tableCell, classes.tableCellPadding)}
            >
              <div className={classes.action}>
                <div
                  className={classes.actionIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteModalOpen(row)
                  }}
                >
                  <i className="fas fa-trash-alt"></i>
                </div>
              </div>
            </TableCell>
          </TableRow>
        ))
    ) : (
      <></>
    );
  return (
    <>
      {rows?.length === 0 || rows === null ? (
        <div className={classes.emptyTable}>データなし</div>
      ) : (
        <TableRoot
          columns={columns}
          rows={tableRows}
          sortHeader={sortHeader}
          reverseFlg={reverseFlg}
          headerClick={(e) => headerSort(e)}
        />
      )}
      <ConfirmModal
        title="ユーザーアカウントを削除する"
        btitle="削除"
        description={`「ユーザー：${fullNameOrFamilyName(
          workRow.full_name,
          workRow.first_name,
          workRow.last_name
        )}」を本当に削除しますか？`}
        show={deleteModalStatus}
        onClose={handleDeleteConfirmClose}
        action={handleDeleteConfirm}
      />
      <ConfirmModal
        title={`ユーザーアカウントを${!workRow.enable ? "有効化" : "無効化"
          }する`}
        btitle={`${!workRow.enable ? "有効化" : "無効化"}`}
        description={`「ユーザー：${fullNameOrFamilyName(
          workRow.full_name,
          workRow.first_name,
          workRow.last_name
        )}」を
          ${!workRow.enable ? "有効化" : "無効化"}しますか？`}
        show={switchConfirm}
        onClose={handleSwitchConfirmClose}
        action={handleSwitchConfirm}
      />
      <ConfirmModal
        title="招待メールを送信"
        btitle="送信"
        description={`「ユーザー：${fullNameOrFamilyName(
          workRow.full_name,
          workRow.first_name,
          workRow.last_name
        )}」に招待メールを送りますか`}
        show={showReinvitationModal}
        onClose={handleReinvitationConfirmClose}
        action={handleReInvitation}
      />
      <MessageModal
        title="エラー"
        description={`現在ログインしているユーザー自身の無効化や削除はできません。`}
        show={errorMessageModalStatus}
        onClose={() => setErrorMessageModalStatus(false)}
        action={handleErrorMessageModal}
      />
      <MessageModal
        title="エラー"
        description={`エラーが発生しました。\n\n<<エラーメッセージ>>\n${errMsg}`}
        show={errorMessage2ModalStatus}
        onClose={() => setErrorMessage2ModalStatus(false)}
        action={handleErrorMessage2Modal}
      />
      <MessageModal
        title="送信完了"
        description="メールの送信が完了しました"
        show={showAfterModal}
        onClose={handleCloseAfterModal}
        action={handleCloseAfterModal}
      />
    </>
  );
};

export default TableContent;
