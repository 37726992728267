import { AfterApiIsLogin } from "Utilities/AuthUtility";
import { REQUEST_API_URL } from "../config/constant";
import { getToken } from "Utilities/appHelper";

export function ApiUsersId(callback: any, id: any) {
  const token = getToken();

  fetch(REQUEST_API_URL + "/users/" + id, {
    method: "GET",
    mode: "cors",
    cache: "no-store",
    credentials: "include",
    redirect: "error",
    referrerPolicy: "no-referrer-when-downgrade",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      AfterApiIsLogin(data["result-status"]);
      callback(data["result-object"]);
    });
}
