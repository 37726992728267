import { REQUEST_API_URL } from "../config/constant";

export async function authClient(email: any, password: any) {
  const response: any = await fetch(REQUEST_API_URL + "/authClient", {
    method: "POST",
    mode: "cors",
    cache: "no-store",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "error",
    body: JSON.stringify({ email: email, password: password }),
  });

  return response.json();
}

export async function getUser(userId: string, token: string) {
  const params = { userId: userId };
  const query = new URLSearchParams(params);
  const response: any = await fetch(REQUEST_API_URL + `/authInfo?${query}`, {
    method: "GET",
    mode: "cors",
    cache: "no-store",
    credentials: "include",
    headers: {
      Authorization: "Bearer " + token,
    },
    redirect: "error",
  });

  return response.json();
}
