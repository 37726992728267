import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
export const SidebarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "fixed",
      textAlign: "center",
      width: "300px",
      boxSizing: "border-box",
      padding: "48px 12px 48px 12px",
      height: "100%",
      zIndex: 3,
      transition: ".3s cubic-bezier(.4,.4,0,1)",
      [theme.breakpoints.down(840)]: {
        display: "none",
      },
    },
    logoA: {
      justifyContent: "center",
    },
    logoImg: {
      width: "100px",
      //height: "44px",
      cursor: "pointer",
      "&:hover": {
        opacity: 0.5,
      },
      [theme.breakpoints.down(400)]: {
        width: "100px",
        height: "25px",
      },
    },
    avatarRoot: {
      marginTop: "40px",
      textAlign: "center",
      cursor: "pointer",
      alignItems: "center",
      flexDirection: "column",
    },
    avatarImg: {
      width: "64px",
      height: "64px",
      borderRadius: "50px",
      marginBottom: "12px",
    },
    avatarName: {
      color: "#242727",
      fontFamily: "Noto Sans JP",
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "1",
    },
    avatarJob: {
      color: "#242727",
      fontFamily: "Noto Sans JP",
      fontSize: "12px",
      fontWeight: 600,
      letterSpacing: "0.1em",
      lineHeight: "1.4",
      height: "16px",
      marginTop: "12px",
      opacity: 0.5,
    },
    logoutBtn: {
      display: "inline-block",
      fontFamily: "Noto Sans JP",
      fontSize: "14px",
      fontWeight: 600,
      border: "1px solid #DCE5E5",
      padding: "6px 16px",
      boxSizing: "border-box",
      cursor: "pointer",
      borderRadius: "8px",
      transition: ".3s ease-out",
      marginTop: "16px",
      marginBottom: "72px",
      [theme.breakpoints.down(540)]: {
        fontSize: "12px",
      },
      "&:hover": {
        backgroundColor: "#F6F7F9",
      },
    },
    sidebarContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderTop: "1px solid #DCE5E5",
      margin: "0 12px",
      boxSizing: "border-box",
    },
    itemRow: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "10px 8px",
      boxSizing: "border-box",
      transition: ".3s cubic-bezier(.4,.4,0,1)",
      cursor: "pointer",
      borderBottom: "1px solid #DCE5E5",
      zIndex: 3,
      "&:hover": {
        backgroundColor: "#F6F7F9",
      },
    },
    itemRowActive: {
      backgroundColor: "#F6F7F9",
    },
    itemRowContainer: {
      width: "100%",
      position: "relative",
    },
    itemRowRight: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    itemImg: {
      background: "rgba(0,0,0,0.0)",
      borderRadius: "8px",
      marginRight: "16px",
      width: "40px",
      height: "40px",
      [theme.breakpoints.down(500)]: {
        margin: 0,
      },
    },
    itemContent: {
      color: "#242727",
      fontFamily: "Noto Sans JP",
      fontSize: "16px",
      fontWeight: 600,
      height: "auto",
      letterSpacing: "0.1em",
      lineHeight: "1.4",
      boxSizing: "border-box",
      textAlign: "start",
      [theme.breakpoints.down(500)]: {
        minWidth: "133px",
      },
      [theme.breakpoints.down(400)]: {
        minWidth: "80px",
        fontSize: "12px",
      },
    },
    itemBtn: {
      "& i": {
        color: "#A8ABAB",
        fontSize: "20px",
      },
    },
    banner: {
      width: "97px",
      height: "19px",
    },
  })
);
