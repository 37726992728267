/** @format */

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#EEF3F2",
      padding: "80px",
      boxSizing: "border-box",
      [theme.breakpoints.down(840)]: {
        padding: "80px 48px 80px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
      [theme.breakpoints.down(540)]: {
        padding: "48px 24px 48px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
      [theme.breakpoints.down(440)]: {
        padding: "24px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
    },
    container: {
      background: "#fff",
      borderRadius: "10px",
      
      padding: "24px",
      textAlign: "center",
      [theme.breakpoints.down(840)]: {
        padding: "12px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
      [theme.breakpoints.down(440)]: {
        padding: "12px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
    },
    clientListTitle: {
      color: "#000",
      //fontFamily: "ヒラギノ角ゴ W6 JIS2004",
      fontFamily: "Noto Sans JP",
      fontSize: "40px",
      fontWeight: 600,
      height: "auto",
      lineHeight: 1.4,
      textAlign: "left",
      transitionDelay: "300ms",
      transitionDuration: "1000ms",
      [theme.breakpoints.down(840)]: {
        fontSize: "24px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
      [theme.breakpoints.down(440)]: {
        marginBottom: "18px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
    },
    clientListSmallTitle: {
      color: "#000",
      //fontFamily: "ヒラギノ角ゴ W6 JIS2004",
      fontFamily: "Noto Sans JP",
      fontSize: "16px",
      fontWeight: 600,
      height: "auto",
      lineHeight: 1.4,
      textAlign: "center",
      transitionDelay: "300ms",
      transitionDuration: "1000ms",
      [theme.breakpoints.down(840)]: {
        fontSize: "16px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
      [theme.breakpoints.down(440)]: {
        marginBottom: "16px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
    },
    tableRoot: {},
    tableTool: {
      display: "flex",
      justifyContent: "right",
      alignItems: "center",
      color: "#000",
      //fontFamily: "ヒラギノ角ゴ W6 JIS2004",
      fontFamily: "Noto Sans JP",
      fontSize: "14px",
      fontWeight: 600,
    },
    titleContainer: {
      padding: "16px 0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
    },
    addBtnParent: {
      display: "flex",
      gap: "8px",
      width: "fit-content",
      height: "auto",
    },
    addBtn: {
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
      gap: "4px",
      cursor: "pointer",
      width: "fit-content",
      color: "#fff",
      height: "auto",
      fontWeight: 600,
      backgroundColor: "#242727",
      padding: "8px 12px",
      borderRadius: "4px",
      "& i": {
        margin: "0 0 0 2px",
        fontWeight: 500,
        fontSize: "12px",
        color: "#fff",
      },
      "&:hover": {
        opacity: 0.5,
      },
    },
    removeBtn: {
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
      gap: "4px",
      cursor: "pointer",
      width: "fit-content",
      color: "#C7010D",
      fontWeight: 600,
      border: "2px solid #C7010D",
      height: "auto",
      backgroundColor: "white",
      padding: "8px 12px",
      borderRadius: " 5px",
      "& i": {
        margin: "0 0 0 2px",
        fontWeight: 500,
        fontSize: "12px",
        color: "#C7010D",
      },
      "&:hover": {
        opacity: 0.5,
      },
    },
    backHomeBtn: {
      margin: "50px auto 0 auto",
    },
    serachContainer: {
      display: "flex",
      justifyContent: "right",
    },
    searchRoot: {
      display: "flex",
      alignItems: "center",
      marginBottom: "20px",
      justifyContent: "right",
      width: "fit-content",
      border: "1px solid #eee",
      borderRadius: "4px",
    },
    searchTitle: {
      color: "#000",
      //fontFamily: "ヒラギノ角ゴ W6 JIS2004",
      fontFamily: "Noto Sans JP",
      fontSize: "18px",
      fontWeight: 600,
      height: "auto",
      lineHeight: 1.4,
      margin: "0 10px",
      minWidth: "50px",
    },
    searchInput: {
      border: "none",
    },
    searchBtn: {
      minWidth: "50px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#F59B8A",
      height: "51px",
      borderBottomRightRadius: "4px",
      borderTopRightRadius: "4px",
      cursor: "pointer",
      "& i": {
        color: "#fff",
      },
      "&:hover": {
        opacity: 0.8,
      },
    },
    loading: {
      paddingTop: "12px"
    }
  })
);
