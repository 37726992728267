import {
  APPLICATION_MAIL_TYPE_BUSINESS,
  APPLICATION_MAIL_TYPE_PRIVATE,
} from "config/constant";
import { isEmpty } from "lodash";

export function ProcServiceApplications(data: any) {
  var result: any = [];
  data.forEach((item: any) => {
    result.push({
      id: item.id,
      application_datetime: item.application_datetime,
      user_id: item.user_id?._id,
      last_name: item.user_id?.last_name,
      first_name: item.user_id?.first_name,
      user_name: item.user_id
        ? item.user_id.last_name + " " + item.user_id.first_name
        : "削除済もしくは表示不可のユーザー",
      user_name_display: item.user_id?.full_name ?? "",
      business_email: item.user_id?.business_email,
      business_email_display: item.user_id ? item.user_id.business_email : "-",
      client_id: item.user_id?.client_id,
      user_enable: item.user_id?.enable,
      user_enable_string: item.user_id
        ? item.user_id.enable
          ? "有効"
          : "無効"
        : "-",
      service_id: item.service_id?._id,
      service_title: item.service_id ? item.service_id.title : "無効なサービス",
      service_title_display:
        item.service_title.length > 20
          ? item.service_title.slice(0, 20) + "..."
          : item.service_title,
      at_that_time_last_name: item.last_name,
      at_that_time_first_name: item.first_name,
      at_that_time_user_name_display: item.last_name + " " + item.first_name,
      at_that_time_service_title: item.service_title,
      at_that_time_service_title_display:
        item.service_title.length > 20
          ? item.service_title.slice(0, 20) + "..."
          : item.service_title,
      at_that_time_email_type: item.email_type,
      at_that_time_email_type_display:
        Number(item.email_type) === APPLICATION_MAIL_TYPE_BUSINESS
          ? "会社メール"
          : Number(item.email_type) === APPLICATION_MAIL_TYPE_PRIVATE
          ? "個人メール"
          : "",
      at_that_time_application_email: item.email,
      at_that_time_client_id: item.client_id,
      devise: !isEmpty(item.devise) ? item.devise : "--",
    });
  });
  return result;
}
