/** @format */

import ActionTable from "components/Table/ActionTable";
import { useState, useEffect, useRef } from "react";
import { useStyles } from "./ApplicationReportStyles";
import {
  ADD_TIME,
  DEFAULT_PER_PAGE_NUM,
  FILTER_MODE_CONTAINS,
  FILTER_MODE_ENDS_WITH,
  FILTER_MODE_STARTS_WITH,
} from "config/constant";

import ReportDetailModal from "./Components/ReportDetailModal/ReportDetailModal";
import { defaultFilterParameters } from "components/Table/TableBase/TableFilter";
import { CSVLink } from "react-csv";

import { ApiServiceApplications } from "Apis/ApiServiceApplications";
import { ProcServiceApplications } from "Utilities/ProcServiceApplications";
import ApplicationTableContent from "./Components/ApplicationReportContent/ApplicationTableContent";
import { CircularProgress } from "@material-ui/core";
import { isEmpty } from "lodash";

export const tableHeaderServiceApplications = [
  "No",
  "氏名",
  "申込日時",
  "サービス名",
];

const filterColumnsServiceApplications = ["氏名", "申込日時"];

export const ApplicationReport = () => {
  const classes = useStyles();

  const defaultSortHeader = 0;
  const defaultReverseFlg = false;
  const [sortHeader, setSortHeader] = useState(defaultSortHeader);
  const [reverseFlg, setReverseFlg] = useState(defaultReverseFlg);

  const [filterParameters, setFilterParameters] = useState<any>(
    defaultFilterParameters
  );

  const [serviceApplicationsArray, setServiceApplicationsArray] = useState<
    Array<any>
  >([]);
  const [serviceApplicationsArrayTmp, setServiceApplicationsArrayTmp] =
    useState<Array<any>>([]);

  const [initFlg, setInitFlg] = useState(true);
  const [outputCsvFetchDone, setOutputCsvFetchDone] = useState(false);
  const [csvOutputHeader, setCsvOutputHeader] = useState<Array<any>>([]);
  const [csvOutputdata, setCsvOutputdata] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);

  const outputCsvFetchDoneRef = useRef<any>();

  useEffect(() => {
    setLoading(true);
    ApiServiceApplications(
      callBackApiServiceApplications(ProcServiceApplications)
    );
  }, []);

  useEffect(() => {
    if (initFlg && serviceApplicationsArray.length > 0) {
      var list: any = [];
      var list2: any = [];

      serviceApplicationsArray.forEach((item: any) => {
        list.push({
          item_type: "MixServiceApplications",
          item_type_output: "サービス利用履歴",
          id: item.id,
          login_id_output: "",
          service_application_id_output: item.id,
          datetime: item.application_datetime,
          datetime_display: mongodbTimeToDisplay(item.application_datetime),
          user_id: item.user_id,
          user_name: item.user_name,
          user_name_display: item.user_name_display,
          business_email_display: item.business_email_display,
          client_id: item.client_id,
          user_enable: item.user_enable,
          user_enable_string: item.user_enable_string,
          at_that_time_client_id: item.at_that_time_client_id,
          login_type_display: "",
          require_auth_type_display: "",
          service_id: item.service_id,
          service_title: item.service_title,
          service_title_display: item.service_title_display,
          at_that_time_user_name_display: item.at_that_time_user_name_display,
          at_that_time_service_title: item.at_that_time_service_title,
          at_that_time_service_title_display:
            item.at_that_time_service_title_display,
          at_that_time_email_type_display: item.at_that_time_email_type_display,
          at_that_time_application_email: item.at_that_time_application_email,
          devise: item.devise,
        });
      });

      list.sort(compare_datetime);
      list.reverse();
      list.forEach((item: any, key: any) => {
        item.no = key + 1;
        list2.push(item);
      });
      setInitFlg(false);
    }
  }, [serviceApplicationsArray]);

  // useEffect(() => {
  //   setSortHeader(defaultSortHeader);
  //   setReverseFlg(defaultReverseFlg);

  //   handleFilter(defaultFilterParameters, true);

  //   setCurrentPage(defaultCurrentPage);
  //   setPerPage(DEFAULT_PER_PAGE_NUM);
  // }, [displayTable]);

  useEffect(() => {
    handleFilter(filterParameters, false);
  }, [serviceApplicationsArray]);

  useEffect(() => {
    if (outputCsvFetchDone) {
      outputCsvFetchDoneRef.current.link.click();
      setOutputCsvFetchDone(false);
    }
  }, [outputCsvFetchDone]);

  function callBackApiServiceApplications(func: any) {
    var list: any = [];
    setLoading(false);
    return (data: any) => {
      data.sort(compare_application_datetime);
      data.reverse();
      func(data).forEach((item: any, key: any) => {
        item.no = key + 1;
        item.item_type = "ServiceApplications";
        item.application_datetime_display = mongodbTimeToDisplay(
          item.application_datetime
        );
        list.push(item);
      });
      setServiceApplicationsArray(list);
    };
  }

  function mongodbTimeToDisplay(strMongoTime: string) {
    if (!(strMongoTime === void 0)) {
      var year = Number(strMongoTime.slice(0, 4));
      var month = Number(strMongoTime.slice(5, 7)) - 1;
      var day = Number(strMongoTime.slice(8, 10));
      var hour = Number(strMongoTime.slice(11, 13));
      var minute = Number(strMongoTime.slice(14, 16));
      var seccond = Number(strMongoTime.slice(17, 19));
      var dt = new Date(year, month, day, hour, minute, seccond);

      dt.setHours(dt.getHours() + ADD_TIME);

      return dt.toLocaleString();
    } else {
      return "-";
    }
  }

  // MEMO:-申込詳細は非表示にする
  // const [reportDetailShowStatus, setReportDetailShowStatus] = useState(false);
  // const [reportInfoItem, setReportInfoItem] = useState<any>();

  // const handleReportDetail = (e: any) => {
  //   setReportInfoItem(e);
  //   setReportDetailShowStatus(true);
  // };

  //--------------display table change----------

  //-----------pagination function start------------------------
  const defaultCurrentPage = 0;
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE_NUM);
  const handlePgNum = (pgNum: number) => {
    setCurrentPage(pgNum);
  };
  const handlePgRows = (rows: number) => {
    setPerPage(rows);
    setCurrentPage(0);
  };

  //-------------header sort function----------
  const handleHeader = (e: any) => {
    var workReverseFlg = false;
    if (e === sortHeader) {
      workReverseFlg = !reverseFlg;
    }
    setReverseFlg(workReverseFlg);

    setSortHeader(e);

    var list: any = [];

    serviceApplicationsArray.forEach((item: any) => {
      list.push(item);
    });

    switch (e) {
      case 0:
        list.sort(compare_no);
        break;
      case 1:
        list.sort(compare_application_datetime);
        break;
      case 2:
        list.sort(compare_user_name_display);
        break;
      case 3:
        list.sort(compare_business_email_display);
        break;
      case 4:
        list.sort(compare_service_title);
        break;
      case 5:
        list.sort(compare_at_that_time_user_name_display);
        break;
      case 6:
        list.sort(compare_at_that_time_service_title);
        break;
      case 7:
        list.sort(compare_at_that_time_email_type_display);
        break;
      case 8:
        list.sort(compare_at_that_time_application_email);
        break;
      default:
    }

    if (workReverseFlg) {
      list.reverse();
    }

    setServiceApplicationsArray(list);
  };

  function compare_no(a: any, b: any) {
    if (a.no < b.no) {
      return -1;
    }
    if (a.no > b.no) {
      return 1;
    }
    return 0;
  }

  function compare_application_datetime(a: any, b: any) {
    if (a.application_datetime < b.application_datetime) {
      return -1;
    }
    if (a.application_datetime > b.application_datetime) {
      return 1;
    }
    return 0;
  }

  function compare_datetime(a: any, b: any) {
    if (a.datetime < b.datetime) {
      return -1;
    }
    if (a.datetime > b.datetime) {
      return 1;
    }
    return 0;
  }

  function compare_user_name_display(a: any, b: any) {
    if (a.user_name_display < b.user_name_display) {
      return -1;
    }
    if (a.user_name_display > b.user_name_display) {
      return 1;
    }
    return 0;
  }

  function compare_business_email_display(a: any, b: any) {
    if (a.business_email_display < b.business_email_display) {
      return -1;
    }
    if (a.business_email_display > b.business_email_display) {
      return 1;
    }
    return 0;
  }

  function compare_service_title(a: any, b: any) {
    if (a.service_title < b.service_title) {
      return -1;
    }
    if (a.service_title > b.service_title) {
      return 1;
    }
    return 0;
  }

  function compare_at_that_time_user_name_display(a: any, b: any) {
    if (a.at_that_time_user_name_display < b.at_that_time_user_name_display) {
      return -1;
    }
    if (a.at_that_time_user_name_display > b.at_that_time_user_name_display) {
      return 1;
    }
    return 0;
  }

  function compare_at_that_time_service_title(a: any, b: any) {
    if (a.at_that_time_service_title < b.at_that_time_service_title) {
      return -1;
    }
    if (a.at_that_time_service_title > b.at_that_time_service_title) {
      return 1;
    }
    return 0;
  }

  function compare_at_that_time_email_type_display(a: any, b: any) {
    if (a.at_that_time_email_type_display < b.at_that_time_email_type_display) {
      return -1;
    }
    if (a.at_that_time_email_type_display > b.at_that_time_email_type_display) {
      return 1;
    }
    return 0;
  }

  function compare_at_that_time_application_email(a: any, b: any) {
    const a_at_that_time_application_email =
      a.at_that_time_application_email !== null
        ? a.at_that_time_application_email
        : "";
    const b_at_that_time_application_email =
      b.at_that_time_application_email !== null
        ? b.at_that_time_application_email
        : "";

    if (
      a_at_that_time_application_email < b_at_that_time_application_email ||
      (a_at_that_time_application_email === "" &&
        b_at_that_time_application_email !== "")
    ) {
      return -1;
    }
    if (
      a_at_that_time_application_email > b_at_that_time_application_email ||
      (a_at_that_time_application_email !== "" &&
        b_at_that_time_application_email === "")
    ) {
      return 1;
    }
    return 0;
  }

  //-------------filter function----------
  const handleFilter = (
    filterParameters: any,
    filterChangeTrigger: boolean
  ) => {
    if (serviceApplicationsArray?.length > 0) {
      var list: any = [];
      var flg = false;

      serviceApplicationsArray.forEach((item: any) => {
        flg = false;
        if (filterParameters.filterText === "") {
          flg = true;
        } else {
          switch (filterParameters.filterColumn) {
            case 0:
              flg = filterMatchCheck(
                item.user_name_display,
                filterParameters.filterText,
                filterParameters.filterMode
              );
              break;
            case 1:
              flg = filterMatchCheck(
                item.application_datetime_display,
                filterParameters.filterText,
                filterParameters.filterMode
              );
              break;
            default:
          }
        }

        if (flg) {
          list.push(item);
        }
      });

      setServiceApplicationsArrayTmp(list);
    }

    if (filterChangeTrigger) {
      setFilterParameters({
        ...filterParameters,
        filterColumn: filterParameters.filterColumn,
        filterMode: filterParameters.filterMode,
        filterText: filterParameters.filterText,
      });
    }
  };

  function filterMatchCheck(
    targetStr: string,
    searchStr: string,
    checkType: number
  ) {
    targetStr = targetStr?.toLowerCase();
    searchStr = searchStr?.toLowerCase();

    var result = false;

    switch (checkType) {
      case FILTER_MODE_CONTAINS:
        result = targetStr?.indexOf(searchStr) >= 0;
        break;
      case FILTER_MODE_STARTS_WITH:
        result = targetStr?.startsWith(searchStr);
        break;
      case FILTER_MODE_ENDS_WITH:
        result = targetStr?.endsWith(searchStr);
        break;
      default:
    }

    return result;
  }

  function handleOutputCsv() {
    setCsvOutputHeader([
      { label: "サービス利用日時", key: "application_datetime_display" },
      { label: "ユーザー氏名", key: "user_name_display" },
    ]);
    setCsvOutputdata(serviceApplicationsArrayTmp);
    setOutputCsvFetchDone(true);
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.reportTitle}>サービス利用履歴</div>
        <div className={classes.reportSmallTitle}>
          以下より、利用履歴を確認できます。
        </div>
        <div className={classes.menuContainer}>
          <div className={classes.outputCsv} onClick={handleOutputCsv}>
            csv出力<i className="far fa-arrow-to-bottom"></i>
            {outputCsvFetchDone && (
              <CSVLink
                data={csvOutputdata}
                headers={csvOutputHeader}
                filename={"output.csv"}
                ref={outputCsvFetchDoneRef}
              />
            )}
          </div>
        </div>
        {loading ? (
          <div className={classes.loading}>
            <CircularProgress size="1rem" color="inherit" />
          </div>
        ) : (
          <ActionTable
            className={classes.tableRoot}
            handlePgNum={handlePgNum}
            handlePgRows={handlePgRows}
            totalCnt={serviceApplicationsArrayTmp?.length}
            PgNum={currentPage}
            PgRows={perPage}
            maxPgRows={serviceApplicationsArrayTmp?.length}
            filterColumns={filterColumnsServiceApplications}
            changeFilterParameters={(e) => handleFilter(e, true)}
            filterParameters={filterParameters}
            tableContent={
              !loading && isEmpty(serviceApplicationsArrayTmp) ? (
                <div className={classes.blankText}>データが存在しません</div>
              ) : (
                <ApplicationTableContent
                  onRow={(e) => {}}
                  rows={serviceApplicationsArrayTmp}
                  pageNumber={currentPage}
                  perPageNumber={perPage}
                  columns={tableHeaderServiceApplications}
                  sortHeader={sortHeader}
                  reverseFlg={reverseFlg}
                  headerSort={(e) => handleHeader(e)}
                />
              )
            }
          />
        )}

        {/* <ReportDetailModal
          show={reportDetailShowStatus}
          reportInfo={reportInfoItem}
          onClose={() => setReportDetailShowStatus(false)}
        /> */}
      </div>
    </div>
  );
};
