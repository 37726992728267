/** @format */

import { useStyles } from "./CsvDeleteModalStyle";
import { useState, useEffect, useRef } from "react";
import ActionButton from "components/ActionButton/ActionButton";
import csvSampleWithPassword from "assets/files/employee_delete_template.xlsx";
import csvSampleWithEmployeeId from "assets/files/employee_delete_template_with_employee_id.xlsx";
import { ENCODING_TYPES } from "config/constant";
import { detectEncodingFailure } from "Utilities/validator";

interface CsvDeleteModalProps {
  show: boolean;
  authType: string;
  onClose: () => void;
  action: (e: any) => void;
}
export default function CsvDeleteModal({
  show,
  authType,
  onClose,
  action,
}: CsvDeleteModalProps) {
  const classes = useStyles();

  const [showStatus, setShowStatus] = useState(show);
  const [csvFile, setCsvFile] = useState(null);

  const rootRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(e: any) {
      if (rootRef && rootRef.current && contentRef && contentRef.current) {
        const root: any = rootRef.current;
        const content: any = contentRef.current;
        if (root.contains(e.target) && !content.contains(e.target)) {
          onClose();
        }
      }
    }
  }, [rootRef, contentRef, show]);

  useEffect(() => {
    setShowStatus(show);
  }, [show]);

  const onFileInputChange = (e: any) => {
    setCsvFile(e.target.files[0]);
  };

  const [errMsg, setErrMsg] = useState<Array<String>>([]);

  const handleNext = async () => {
    const confirmStatus = window.confirm("本当に削除を行いますか");
    if (confirmStatus === false) return;
    var userList: any = [];
    let errs: any = [];

    if (csvFile !== null) {
      let result: string | null = null;
      for (const encoding of ENCODING_TYPES) {
        result = await new Promise((resolve) => {
          var reader = new FileReader();
          reader.onload = (f) => {
            resolve(reader.result as string);
          };
          reader.onerror = function (e) {
            resolve(reader.result as string);
          };
          reader.readAsText(csvFile, encoding);
        });
        console.log(`result with ${encoding}:`, result);
        if (result && !detectEncodingFailure(result)) {
          break; // 正常に読み込めた場合はループを抜ける
        }
      }
      if (result !== null) {
        userList = parseCsv(result, ",");
      } else {
        errs.push(
          "ファイルを読み込めませんでした。（ファイルを更新した場合は、一度キャンセルボタンを押してください）"
        );
      }
    } else {
      errs.push("ファイルが選択されていません。");
    }

    userList.splice(0, 1);

    let inputProps: any = {
      userList: userList,
    };

    if (errs.length > 0) {
    } else if (userList.length > 0) {
      if (userList.length <= 1000) {
        userList.forEach(function (row: any, i: any) {
          var j = i + 2;

          if (authType === "password") {
            if (row[0] === void 0 || row[0].length == 0) {
              errs.push(j + "行目 会社メールアドレスが入力されていません。");
            } else {
              if (!/^[^@]+@[^@.]+.[^@]+$/.test(row[0])) {
                errs.push(
                  j + "行目 会社メールアドレスの形式が正しくありません。"
                );
              }
            }
          } else if (authType === "employee_id") {
            if (row[0] === void 0 || row[0].length == 0) {
              errs.push(j + "行目 従業員番号が入力されていません。");
            }
          }
        });
      } else {
        errs.push("1度に削除できるデータは1000件までです。");
      }
    } else {
      errs.push("データがありません。");
    }

    setErrMsg([]);
    if (errs.length > 0) {
      let temp = errs;
      setErrMsg(temp);
    } else {
      action(inputProps);
    }
  };

  function parseCsv(csvStr: any, delimiter: any) {
    var rowRegex = /(?:(?:"[^"]*")*[^\r\n"]*)+/g,
      colRegex = new RegExp('(?:(?:"[^"]*")*[^' + delimiter + '"]*)+', "g"),
      rows = [],
      row,
      cells,
      cell,
      rowMaches,
      colMaches;
    while ((rowMaches = rowRegex.exec(csvStr)) !== null) {
      if (rowMaches[0] !== "") {
        cells = [];
        row = rowMaches[0];
        //セルを切り出す
        while ((colMaches = colRegex.exec(row)) !== null) {
          cell = colMaches[0].replace(/^\s+|\s+$/g, "");
          if (cell.charAt(0) == '"' && cell.charAt(cell.length - 1) == '"') {
            cell = cell.slice(1, -1);
          }
          cell = cell.replace(/""/g, '"');
          cells.push(cell);
          colRegex.lastIndex++; //一歩前へ！
        }
        rows.push(cells);
      }
      rowRegex.lastIndex++; //一歩前へ！
    }
    return rows;
  }

  return (
    <>
      <div
        className={showStatus ? classes.loaderWrapper : classes.displayNone}
        ref={rootRef}
      >
        <div className={classes.modalRoot} ref={contentRef}>
          <div className={classes.title}>
            従業員一括削除（ファイルアップロード）
          </div>
          <div className={classes.descContainer}>
            <p>ファイルを指定し、追加ボタンを押してください。</p>
            <div className={classes.container}>
              <div className={classes.descDetail}>
                <strong>■ 一括削除方法</strong>
                <br />
                ①テンプレートのxslxファイルをダウンロード
                <br />
                ②Excelの2行目に削除対象の従業員のメールアドレスを記入
                <br />
                ③ExcelをCSVファイル(UTF-8形式)で保存して、アップロード
                <br />
                ④削除処理が実行されます
              </div>
              <div className={classes.mainContainerFlex}>
                <div className={classes.mainContainer}>
                  <div className={classes.form}>
                    <div className={classes.formTitle}>テンプレート</div>
                    <div className={classes.descDetail}>
                      <a
                        href={
                          authType === "employee_id"
                            ? csvSampleWithEmployeeId
                            : csvSampleWithPassword
                        }
                        download="従業員一括削除.xlsx"
                        className={classes.smallLink}
                      >
                        ダウンロード
                      </a>
                    </div>
                  </div>
                </div>
                <div className={classes.mainContainer}>
                  <div className={classes.form}>
                    <div className={classes.formTitle}>
                      アップロード <span className={classes.symbol}>*</span>
                    </div>
                    <input
                      type="file"
                      accept="text/csv"
                      className={classes.fileInput}
                      onChange={(e) => onFileInputChange(e)}
                    />
                  </div>
                </div>
              </div>

              <div className={classes.errorMessage}>
                {errMsg.length > 0 ? (
                  <>
                    <div>入力エラー</div>
                    <ul>
                      {errMsg.map((item: any, key: any) => {
                        return <li key={key}>{item}</li>;
                      })}
                    </ul>
                  </>
                ) : null}
              </div>
              <div className={classes.btnContainer}>
                <ActionButton
                  content="キャンセル"
                  className={classes.cancelBtn}
                  action={onClose}
                />
                <ActionButton
                  content="一括削除"
                  type="dark"
                  className={classes.confirmBtn}
                  action={handleNext}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
